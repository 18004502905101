import WebServiceRequest from '../Api/WebServiceRequest'

class UsersChangeUserRoleRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('User/ChangeUserRole?userId='+data.userId+'&UserTypeId='+data.UserTypeId)
    }
}
class UserCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('User/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UsersGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('User/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class UsersGeByTokenRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('User/GetByToken')
    }

}
class UsersUpdateByAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('User/UpdateForAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UserTypeGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserType/GetAll')
    }
}
class UsersGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('User/Get?id='+id)
    }
}
class UserGetVendorIndexDataRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('User/GetVendorIndexData')
    }

    setParams(data){
        super.setRequestParam(data)
    }
}
class UserGetVendorDataRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('User/GetVendorData')
    }

    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    UsersChangeUserRoleRequest,
    UserCreateRequest,
    UsersGetAllRequest,
    UsersGeByTokenRequest,
    UsersUpdateByAdminRequest,
    UserTypeGetAllRequest,
    UsersGetRequest,
    UserGetVendorIndexDataRequest,
    UserGetVendorDataRequest
};
